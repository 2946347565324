import React from "react";
import "./desk-title.scss";
import { useTranslation } from "react-i18next";
import useColor from "../../../hooks/other/useColor";

export default function DeskTitle({ iconTitle, title }) {
  const { t } = useTranslation();
  const { headerStyle } = useColor();

  return (
    <h3 className="desk-title">
      {iconTitle} <span style={headerStyle}>{t(title)}</span>
    </h3>
  );
}
