import React from "react";
import "./bonus-menu.scss";
import { useTranslation } from "react-i18next";
import CusButton from "../../../mui/custom_button/CusButton";
import imgPresent from "./present.png";
import useNavigateWithSearch from "../../../../hooks/useNavigateWitchSearch";
import { useAppSelector } from "../../../../store/index.ts";

export default function BonusBannerMenu() {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearch("/");
  const isMenuAbsolute = useAppSelector((state) => state.deviceSize.isMenuAbsolute);
  const imgBonusMenuDesktop = useAppSelector((state) => state.common.bonusImages?.desktop?.menu);
  const imgBonusMenuMobile = useAppSelector((state) => state.common.bonusImages?.mobile?.menu);
  const imgBonusMenu = isMenuAbsolute ? imgBonusMenuMobile : imgBonusMenuDesktop;

  const onGo = () => {
    navigate("/promotions");
  };

  return imgBonusMenu ? (
    <div className="bonus-menu__img__container">
      <img className="bonus-menu__img" src={imgBonusMenu} onClick={onGo} />
    </div>
  ) : (
    <div className="bonus-menu">
      <h2>{t("Play casino games and get bonuses!")}</h2>
      <CusButton color="custom" text={"Bonuses"} onClick={onGo} />
      <img src={imgPresent} />
      <div className="bonus-menu__gradient" />
    </div>
  );
}
