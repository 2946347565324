import React from "react";
import "./error-site-modal.scss";
import DefaultModal from "../DefaultModal";
import { useTranslation } from "react-i18next";

export default function ErrorSiteModal({ isOpen, closeModal }) {
  const { t } = useTranslation();

  return (
    <DefaultModal classModal={"error-modal"} isOpen={isOpen} closeModal={closeModal}>
      <h2>{t("An error has occurred")}</h2>

      <div className="error-modal__content">
        <p>{t("Try refreshing the page")}</p>

        <span
          className="error-modal__refresh"
          onClick={() => {
            document.location.reload();
          }}>
          “{t("Refresh")}”
        </span>
      </div>
    </DefaultModal>
  );
}
