import React, { useEffect, useRef, useState } from "react";
import "./profile-modal.scss";
import DefaultModal from "../DefaultModal";
import { useTranslation } from "react-i18next";
import CusInput from "../../mui/custom_input/CusInput";
import IconMail from "../../mui/JSXIcons/IconMail";
import CusButton from "../../mui/custom_button/CusButton";
import IconLock from "../../mui/JSXIcons/IconLook";
import CusForm from "../../mui/CusForm";
import { API_USER } from "../../../api/user";
import { useAppSelector } from "../../../store/index.ts";
import { ENUM_ACCOUNT_REGISTER, SITE_KEY } from "../../../store/enums.js";
import ShowContainer from "../../mui/ShowContainer.jsx";
import ReCAPTCHA from "react-google-recaptcha";
import { errorToast, successToast } from "../../mui/Toaster.jsx";
import { messageByCode } from "../../../utils/utils.js";
import { useDispatch } from "react-redux";
import { setInfo } from "../../../store/userSlice.js";

export default function ProfileModal({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const email = useAppSelector((state) => state.user.info?.email) || "test@test.com";
  const registerType = useAppSelector((state) => state.user.info.register);
  const lang = useAppSelector((state) => state.common.lang);
  const [pas1, setPas1] = useState("");
  const [pas2, setPas2] = useState("");
  const [error, setError] = useState("");

  /**
   * @type {React.MutableRefObject<ReCAPTCHA | null>} captchaRef
   */
  const captchaRef = useRef(null);
  const isRegisterEmail = registerType === ENUM_ACCOUNT_REGISTER.email;

  const onEmailSubmit = (_, fields) => {
    API_USER.changeEmail(fields)
      .then((e) => {
        const dataError = e.data.dataError;
        const error = e.error;

        if (dataError) return errorToast(messageByCode(dataError));
        if (error) errorToast(error);

        dispatch(
          setInfo({
            nickname: fields.newEmail,
            email: fields.newEmail,
            register: ENUM_ACCOUNT_REGISTER.email
          })
        );
        successToast(t("mail changed successfully"));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onPasSubmit = (_, fields) => {
    console.log(fields);

    const captcha = captchaRef.current?.getValue?.();

    if (pas1 !== pas2) {
      setError("Passwords must match");
      return;
    }

    API_USER.changePassword({ ...fields, captcha })
      .then((e) => {
        const data = e.data;

        const error = data.dataError || data.error;
        if (error) {
          errorToast(data.dataError ? t(messageByCode(data.dataError)) : data.error);
          return;
        }

        successToast(t("Password changed successfully"));
        closeModal();
      })
      .catch((e) => {
        console.log(e);
        captchaRef.current?.reset?.();
      });
  };

  useEffect(() => {
    if (!isOpen) return;

    setError(false);
    captchaRef.current?.reset?.();
  }, [isOpen]);

  return (
    <DefaultModal miniModal isOpen={isOpen} closeModal={closeModal} classModal="profile-modal">
      <h2>{t("Personal information")}</h2>
      <ShowContainer condition={isRegisterEmail}>
        <CusForm className="email" onSubmit={onEmailSubmit}>
          <p>{t("You can change e-mail")}</p>
          <CusInput required placeholder={email} name={"newEmail"} iconJSXstart={<IconMail />} />
          <div className="buttons">
            <CusButton border text={"Change e-mail"} color="gradient-blue" />
          </div>
        </CusForm>

        <CusForm className="password" onSubmit={onPasSubmit}>
          <p>{t("Change password")}</p>
          <CusInput
            required
            name={"oldPassword"}
            placeholder={"Old password"}
            type="password"
            iconJSXstart={<IconLock />}
          />
          <CusInput
            required
            name={"newPassword"}
            placeholder={"New password"}
            min={8}
            max={100}
            type="password"
            autocomplete="new-password"
            onChange={(e) => setPas1(e.target.value)}
            iconJSXstart={<IconLock />}
          />
          <CusInput
            required
            name={"newPassword2"}
            placeholder={"Confirm new password"}
            min={8}
            max={100}
            type="password"
            errorWarning={error}
            onChange={(e) => setPas2(e.target.value)}
            iconJSXstart={<IconLock />}
          />
          <ReCAPTCHA className="captcha" ref={captchaRef} hl={lang} sitekey={SITE_KEY} />

          <div className="buttons">
            <CusButton border text={"Change"} color="gradient-blue" />
            <CusButton text={"Cancel"} type={"reset"} onClick={() => captchaRef.current?.reset()} />
          </div>
        </CusForm>
      </ShowContainer>
    </DefaultModal>
  );
}
