import React from "react";
import DynamicBanners from "../../../promotions/top_bonus_banner/dynamic_banners/DynamicBanners";
import imgGirlWelcome from "./welcome-girl.png";
import useNavigateWithSearch from "../../../../hooks/useNavigateWitchSearch";
import CusButton from "../../../mui/custom_button/CusButton";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store/index.ts";

export default function WelcomeBanner({ defaultBanners }) {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearch();
  const isExtraSmall = useAppSelector((state) => state.deviceSize.isExtraSmall);

  return defaultBanners ? (
    <section
      className="banners__welcome"
      onClick={() => {
        if (isExtraSmall) return;
        navigate("/games/all");
      }}>
      <div className="banners__welcome__info">
        <h2>{t("Welcome to the world of luck and big winnings!")}</h2>
        <p>{t("Start playing now!")}</p>
        <CusButton to={"/games/all"} text={"Start the game"} color="blue" />
      </div>
      <img src={imgGirlWelcome} />
      <div className="bottom-blur" />
    </section>
  ) : (
    <DynamicBanners />
  );
}
