import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/index.ts";
import { useDispatch } from "react-redux";
import { setLockLoginClose, setModalLogin, setTypeLogin } from "../../store/siteSlice";
import { ENUM_LCS_NAME, ENUM_LOGIN } from "../../store/enums";
import { useReadLocalStorage } from "usehooks-ts";

const ENUM_HASH = {
  signup: "#signup",
  signup_on: "#signup_on"
};

export default function useUrlParc() {
  const userId = useAppSelector((state) => state.user.userId);
  const isTgBot = useAppSelector((state) => state.common.isTgBot);
  const alreadyAccount = useReadLocalStorage(ENUM_LCS_NAME.alreadyAccount);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) return;
    if (isTgBot) return;
    const hash = location.hash;

    // проверка только на одно, потому что #signup_up включает в себя #signup
    if (alreadyAccount) {
      if (hash.includes(ENUM_HASH.signup)) {
        dispatch(setTypeLogin(ENUM_LOGIN.login));
        dispatch(setModalLogin(true));
        dispatch(setLockLoginClose(false));
      }
      return;
    }

    // если вообще нет включения #signup то выход
    if (!hash.includes(ENUM_HASH.signup)) return;

    const isLock = hash.includes(ENUM_HASH.signup_on);
    dispatch(setTypeLogin(ENUM_LOGIN.register));
    dispatch(setModalLogin(true));
    dispatch(setLockLoginClose(isLock));
  }, [userId, isTgBot, location, alreadyAccount]);
}
