import React, { useEffect } from "react";
import "./promotions-main.scss";
import FindGame from "../landing/find_game/FindGame";
import StaticBonus from "./static_bonus/StaticBonus";
import Back from "../mui/back/Back";
import IconBonus from "../mui/JSXIcons/IconBonus";
import { API_CASINO } from "../../api/casino";
import { useReadLocalStorage } from "usehooks-ts";
import { useDispatch } from "react-redux";
import {
  setBonusCurrency,
  setCashback,
  setFirstDeposit,
  setForUser,
  setRegistration
} from "../../store/promotionsSlice.js";
import { useAppSelector } from "../../store/index.ts";
import { TEST_MODE } from "../../store/enums.js";
import { testBonus } from "../../store/testConst.js";
import ErrorBoundary from "../mui/error_boundary/ErrorBoundary.jsx";
import useGetLink from "../../hooks/other/useGetLink.js";

export default function PromotionsMain() {
  const home = useGetLink("/");
  const start_r = useReadLocalStorage("start_r");
  const isAuth = useAppSelector((state) => state.user.isAuth);
  const userId = useAppSelector((state) => state.user.userId);
  const currency = useAppSelector((state) => state.user.currency);

  const dispatch = useDispatch();

  useEffect(() => {
    if (TEST_MODE) {
      const data = testBonus;
      dispatch(setBonusCurrency(data.currency));
      dispatch(setCashback(data.cashback));
      dispatch(setFirstDeposit(data.firstDeposit));
      dispatch(setRegistration(data.registration));
      return;
    }

    API_CASINO.getBonuses({ start_r })
      .then((e) => {
        const data = e.data;
        dispatch(setBonusCurrency(data.currency));
        dispatch(setCashback(data.cashback));
        dispatch(setFirstDeposit(data.firstDeposit));
        dispatch(setRegistration(data.registration));
        dispatch(setForUser(userId));
      })
      .catch((e) => {
        console.log(e);
      });
  }, [isAuth, userId, currency]);

  return (
    <section className="promotions">
      <Back icon={<IconBonus />} to={home} title={"Bonuses and promotions"} />
      {/* <TopBonusBanner /> */}
      <FindGame />
      <ErrorBoundary>
        <StaticBonus />
      </ErrorBoundary>
      {/* TODO - пока на сайте нет фриспинов */}
      {/* <FreeSpin /> */}
    </section>
  );
}
