import React from "react";
import "./loader-container.scss";
import Spinner from "../spinner/Spinner";

export default function LoaderContainer({ loader, children, className = "", center, fullHeight }) {
  const loaderClass = loader ? "loader" : "";
  const fullHeightClass = fullHeight ? "full-height" : "";

  return center ? (
    loader ? (
      <div className={`loader-container-center ${loaderClass} ${fullHeightClass}`}>
        <Spinner className={className} />
      </div>
    ) : (
      children
    )
  ) : (
    <>{loader ? <Spinner className={className} /> : children}</>
  );
}
