import React from "react";
import "./header-auth.scss";
import Online from "../online/Online";
import CusButton from "../../../mui/custom_button/CusButton";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalBalance,
  setModalLogin,
  setPromoCode,
  setTypeLogin,
  setWithdrawal
} from "../../../../store/siteSlice";
import { ENUM_LOGIN } from "../../../../store/enums";
import ChooseCurrency from "../../../mui/choose_currency/ChooseCurrency";
import IconCirclePlus from "../../../mui/JSXIcons/IconCirclePlus";
import IconCircleMinus from "../../../mui/JSXIcons/IconCircleMinus";
import AvatarPopup from "./avatar_popup/AvatarPopup";
import ShowContainer from "../../../mui/ShowContainer";

export default function HeaderAuth() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.user.isAuth);
  const isMenuAbsolute = useSelector((state) => state.deviceSize.isMenuAbsolute);

  const onLoginClick = () => {
    dispatch(setTypeLogin(ENUM_LOGIN.login));
    dispatch(setModalLogin(true));
  };

  const onSignUpClick = () => {
    dispatch(setTypeLogin(ENUM_LOGIN.register));
    dispatch(setModalLogin(true));
  };

  return (
    <div className="header__auth">
      <Online />
      {isAuth ? (
        <>
          <div className="header__auth__auth">
            <ChooseCurrency isFullVariant={isMenuAbsolute} />
            <ShowContainer condition={!isMenuAbsolute}>
              <CusButton
                border
                icon={<IconCirclePlus />}
                className="plus"
                color="custom"
                text={"Replenish"}
                onClick={() => dispatch(setModalBalance(true))}
              />
              <CusButton
                className="minus"
                icon={<IconCircleMinus />}
                text={"Withdraw"}
                onClick={() => dispatch(setWithdrawal(true))}
              />
              <CusButton
                className="promo"
                text={"Promo codes"}
                onClick={() => dispatch(setPromoCode(true))}
              />
            </ShowContainer>
          </div>
          <AvatarPopup />
        </>
      ) : (
        <>
          <CusButton text={"Login"} onClick={onLoginClick} />
          <CusButton text={"Sign up"} color="two" onClick={onSignUpClick} />
        </>
      )}
    </div>
  );
}
