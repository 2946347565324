import { useAppSelector } from "../../store/index.ts";

export default function useColor() {
  const logoColor = useAppSelector((state) => state.common.logoColor);
  const buttonColor = useAppSelector((state) => state.common.buttonColor);
  const headerColor = useAppSelector((state) => state.common.headerColor);
  const bkgColor = useAppSelector((state) => state.common.bkgColor);

  const logo = logoColor || "var(--white)";
  const button = buttonColor || "var(--primary-btn)";
  const header = headerColor || "var(--white)";
  const body = bkgColor || "var(--landing-background)";

  const logoStyle = { color: logo };
  const buttonStyle = { background: button };
  const headerStyle = { color: header };
  const bodyStyle = { background: body };

  // const style = logoColor
  // ? {
  //     background: `linear-gradient(180deg, ${logoColor}, #fff) text`,
  //     WebkitTextFillColor: "transparent"
  //   }
  // : undefined;

  return {
    logoStyle,
    buttonStyle,
    headerStyle,
    bodyStyle
  };
}
