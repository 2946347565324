import { useMemo } from "react";
import { useAppSelector } from "../../store/index.ts";

export default function useBanners() {
  const bannersAll = useAppSelector((state) => state.common.banners);
  const lang = useAppSelector((state) => state.common.lang);
  const banners = useMemo(
    () => bannersAll.filter((el) => el.language === lang),
    [bannersAll, lang]
  );

  return banners;
}
