import React from "react";
import "./main.scss";
import Banners from "../components/landing/banners/Banners";
import CatalogGame from "../components/landing/catalog_game/CatalogGame";
import Header from "../components/landing/header/Header";
import Footer from "../components/landing/footer/Footer";
import { ENUM_GAME_TYPE } from "../store/enums";
import FindGameLine from "../components/landing/find_game_line/FindGameLine";
import PageWitchMenu from "../components/mui/page_witch_menu/PageWitchMenu";
import Favorites from "../components/landing/favorites/Favorites";
import useScrollUp from "../hooks/useScrollUp";
import LastChampions from "../components/landing/last_champions/LastChampions";
import Jackpot from "../components/landing/jackpot/Jackpot";
import TypeGamesMenu from "../components/landing/type_games_menu/TypeGamesMenu";
import ErrorBoundary from "../components/mui/error_boundary/ErrorBoundary";
import LoaderContainer from "../components/mui/loader_container/LoaderContainer";
import { useAppSelector } from "../store/index.ts";

export default function Main() {
  useScrollUp();
  const loader = useAppSelector((state) => state.site.mainLoader);
  const errorInfo = useAppSelector((state) => state.site.errorInfo);

  return (
    <LoaderContainer center loader={loader || errorInfo} fullHeight>
      <PageWitchMenu className={"landing"}>
        <ErrorBoundary>
          <Header />
        </ErrorBoundary>
        <ErrorBoundary>
          <LastChampions />
        </ErrorBoundary>
        <ErrorBoundary>
          <Banners />
        </ErrorBoundary>
        <ErrorBoundary>
          <Jackpot />
        </ErrorBoundary>
        <ErrorBoundary>
          <TypeGamesMenu activeTab={ENUM_GAME_TYPE.lobby} />
        </ErrorBoundary>
        <ErrorBoundary>
          <FindGameLine />
        </ErrorBoundary>
        <ErrorBoundary>
          <CatalogGame />
        </ErrorBoundary>
        <ErrorBoundary>
          <Favorites />
        </ErrorBoundary>
        {/* <LatestRates /> */}
        <ErrorBoundary>
          <Footer />
        </ErrorBoundary>
      </PageWitchMenu>
    </LoaderContainer>
  );
}
