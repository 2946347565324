import React, { useEffect, useMemo, useState } from "react";
import DefaultModal from "../DefaultModal";
import CloseModal from "../../mui/close_modal/CloseModal";
import { DEBUG, ENUM_BALANCE_TYPE, TEST_MODE } from "../../../store/enums";
import TopUpCrypto from "./crypto/TopUpCrypto";
import TopUpReal from "./real/TopUpReal";
import { useTranslation } from "react-i18next";
import "./top-up-balance.scss";
import { useDispatch, useSelector } from "react-redux";
import { API_USER } from "../../../api/user";
import { setCurrency } from "../../../store/userSlice";
import { testDeposit } from "../../../store/testConst";
import TopUpBonus from "./bonus/TopUpBonus";
import LoaderContainer from "../../mui/loader_container/LoaderContainer";
import TopUpSum from "./summ/TopUpSum";
import TopUpCards from "./cards/TopUpCards";
import { ENUM_COMMON_EVENT } from "../../../hooks/common/useListener";
import { dispatchCusEvent } from "../../../utils/utils";
import ErrorBoundary from "../../mui/error_boundary/ErrorBoundary";
import { errorToast } from "../../mui/Toaster";

export default function TopUpBalance({ isOpen, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.user.currency);
  const balance = useSelector((state) => state.user.balance);
  const type = useMemo(() => balance?.[currency]?.info?.type, [currency, balance]);

  const isCrypto = useMemo(() => type === ENUM_BALANCE_TYPE.crypto, [type]);
  const [loader, setLoader] = useState(true);
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});
  const [min, setMin] = useState(undefined);
  const [max, setMax] = useState(undefined);

  const title = useMemo(
    () =>
      currency ? t("Top up your balance in", { currency: currency }) : t("Top up your balance"),
    [currency, t]
  );

  const checkOpen = (data) => {
    if (!isOpen) return;
    const sessionError = ENUM_COMMON_EVENT.sessionError;

    if (data.errorCode === 1) dispatchCusEvent(sessionError);
  };

  useEffect(() => {
    setStep(1);
    // if (TEST_MODE) {
    //   setData({
    //     currency: "RUB",
    //     amount: 500
    //   });
    //   setStep(2);
    // }
    if (!isOpen) return;
    // if (isCrypto) return;
    if (!currency) return;
    if (TEST_MODE) {
      const data = testDeposit;
      dispatch(setCurrency(data.currency));
      setMin(data.minAmount);
      setMax(data.maxAmount);
      setLoader(false);
      return;
    }

    API_USER.balance
      .limit()
      .then((e) => {
        if (DEBUG) console.log("limit deposit res", e);
        const data = e.data;
        if (!data) errorToast(t("An error has occurred"));
        checkOpen(data);
        // dispatch(setCurrency(data.currency));
        setMin(data.minAmount);
        setMax(data.maxAmount);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [isOpen, isCrypto, currency]);

  const StepContainer = () => {
    if (step === 1) {
      return (
        <LoaderContainer loader={loader} center>
          <ErrorBoundary>
            <TopUpBonus />
          </ErrorBoundary>
          {isCrypto ? (
            <TopUpCrypto currency={currency} min={min} setStep={setStep} closeModal={closeModal} />
          ) : (
            <TopUpReal
              currency={currency}
              closeModal={closeModal}
              max={max}
              min={min}
              setStep={setStep}
              setData={setData}
            />
          )}
        </LoaderContainer>
      );
    }

    if (step === 2) {
      return (
        <>
          <TopUpSum currency={data.currency} value={data.amount} setStep={setStep} />
          <TopUpCards amount={data.amount} currency={data.currency} closeModal={closeModal} />
        </>
      );
    }
  };

  return (
    <DefaultModal isOpen={isOpen} closeModal={closeModal} classModal="top-up-balance" miniModal>
      <CloseModal close={closeModal} />
      <h2>{title}</h2>

      <StepContainer />
    </DefaultModal>
  );
}
