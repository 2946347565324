import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconWarning from "../../../mui/JSXIcons/IconWarning";
import CusInput from "../../../mui/custom_input/CusInput";
import { API_USER } from "../../../../api/user";
import { DEBUG, TEST_MODE } from "../../../../store/enums";
import { errorToast } from "../../../mui/Toaster";
import { messageByCode } from "../../../../utils/utils";
import LoaderContainer from "../../../mui/loader_container/LoaderContainer";
import ShowContainer from "../../../mui/ShowContainer";

export default function TopUpCrypto({ currency, min, max, closeModal }) {
  const { t } = useTranslation();

  const [address, setCryptoAddress] = useState("");
  const [network, setNetwork] = useState("");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (!currency) return;
    if (TEST_MODE) {
      setLoader(false);
      return;
    }

    const data = { currency };
    API_USER.balance
      .getCryptoAddress(data)
      .then((e) => {
        if (DEBUG) console.log("get address res", e);
        const { address, network } = e.data;

        if (e.dataError) {
          errorToast(messageByCode(e.dataError));
        }

        if (address || network) {
          setCryptoAddress(address);
          setNetwork(network);
          console.log("a", address, network);

          return;
        }
      })
      .catch((e) => {
        console.log(e);
        errorToast(t("An error occurred"));
        closeModal();
      })
      .finally(() => {
        setLoader(false);
      });
  }, [currency]);

  return (
    <LoaderContainer loader={loader} center>
      {address && network ? (
        <div className="top-up top-up__crypto">
          <p>
            {t("Send")} {min} {currency}{" "}
            {t(
              "or more to the address below, and the amount will be credited to your balance minus the commission from"
            )}{" "}
            <span>{network}</span> {t("(approximately 1 USD and 1% of the deposit amount).")}
          </p>

          <p>
            {t(
              "Enrollment will take place automatically after confirmation by the crypto network."
            )}
          </p>

          <div className="top-up__crypto__warning">
            <IconWarning color="#f00" />
            <span className="top-up__crypto__warning__text">
              <span>{t("Send online only")}</span>
              <span className="top-up__crypto__warning__red">
                <span>{currency}</span> <span>{network}</span>
              </span>
            </span>
          </div>

          <CusInput isCopy readOnly defaultValue={address} />

          <div className="top-up__crypto__min-deposit">
            <IconWarning />
            <ShowContainer condition={min}>
              <span className="top-up__crypto__min-deposit__info">
                <span>{t("Minimum deposit")}</span>
                <span className="currency">
                  <span>{min}</span> <span>{currency}</span>
                </span>
              </span>
            </ShowContainer>
            <ShowContainer condition={max}>
              <span className="top-up__crypto__min-deposit__info">
                <span>{t("Maximum deposit")}</span>
                <span className="currency">
                  <span>{max}</span> <span>{currency}</span>
                </span>
              </span>
            </ShowContainer>
          </div>
        </div>
      ) : (
        <div className="top-up top-up__crypto error">
          <h2>{t("An error has occurred")}</h2>
        </div>
      )}
    </LoaderContainer>
  );
}
