import React, { useRef } from "react";
import useFixCarouselDesk from "../../../hooks/useFixCarouselDesk";
import ReactOwlCarousel from "react-owl-carousel";

/**
 *
 * @param {import("react-owl-carousel").OwlCarouselProps} props
 */
export default function CustomCarousel({ children, ...props }) {
  const carouselRef = useRef(null);
  useFixCarouselDesk({ sliderRef: carouselRef, children });

  return (
    <div>
      <ReactOwlCarousel {...props} ref={carouselRef}>
        {React.Children.map(children, (child, index) => (
          <div key={index} id={`item-${index}`} className="carousel-item" data-index={index}>
            {child}
          </div>
        ))}
      </ReactOwlCarousel>
    </div>
  );
}
