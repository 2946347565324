import React from "react";
import "./banners.scss";
import { useTranslation } from "react-i18next";
import CusButton from "../../mui/custom_button/CusButton";
import imgGirlBonus from "./bonus-girl.png";
import useNavigateWithSearch from "../../../hooks/useNavigateWitchSearch.js";
import WelcomeBanner from "./welcome_banner/WelcomeBanner.jsx";
import useBanners from "../../../hooks/other/useBanners.js";
import ShowContainer from "../../mui/ShowContainer.jsx";
import { useAppSelector } from "../../../store/index.ts";
import CarouselOrTag from "../../mui/CarouselOrTag.jsx";

export default function Banners() {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearch();
  const banners = useBanners();

  const imgBonusMainDesktop = useAppSelector((state) => state.common.bonusImages?.desktop?.main);
  const imgBonusMainMobile = useAppSelector((state) => state.common.bonusImages?.mobile?.main);

  const isExtraSmall = useAppSelector((state) => state.deviceSize.isExtraSmall);

  const imgBonusMain = isExtraSmall ? imgBonusMainMobile : imgBonusMainDesktop;

  const defaultBanners = !banners.length;
  const emptyDesktop = !banners.filter((el) => el.fileUrl).length && !defaultBanners;
  const isTwoBannersClass = defaultBanners || (banners.length && imgBonusMain) ? "two" : "one";

  return (
    <ShowContainer
      condition={isExtraSmall || !emptyDesktop}
      plug={<article className="banners"></article>}>
      <CarouselOrTag
        tag={"article"}
        className={`banners ${isTwoBannersClass}`}
        isCarousel={defaultBanners && isExtraSmall}
        items={1}
        margin={12}
        loop={true}
        autoplaySpeed={500}
        autoplay>
        <WelcomeBanner defaultBanners={defaultBanners} />

        <ShowContainer condition={defaultBanners}>
          <section className="banners__bonus">
            <div className="banners__bonus__info">
              <h2>{t("All bonuses for you:")}</h2>
              <p>{t("All bonuses in one place: welcome, no deposit and much more")}</p>
              <CusButton
                to={"/promotions"}
                text={"Go"}
                color="blue"
                onClick={() => {
                  navigate("/promotions");
                }}
              />
            </div>
            <img src={imgGirlBonus} />
            <div className="bottom-blur" />
          </section>
        </ShowContainer>

        <ShowContainer condition={imgBonusMain && !defaultBanners}>
          <section
            className="banners__bonus__custom"
            onClick={() => {
              navigate("/promotions");
            }}>
            <img src={imgBonusMain} />
          </section>
        </ShowContainer>
      </CarouselOrTag>
    </ShowContainer>
  );
}
