import React from "react";
import { useSelector } from "react-redux";
import GameItem from "../mui/game_tem/GameItem";
import useColor from "../../hooks/other/useColor";

export default function ProvidersAllGames() {
  const providers = useSelector((state) => state.games.providers);
  const providersGames = useSelector((state) => state.games.providersGames);
  const { headerStyle } = useColor();

  const GamesItems = ({ games }) => (
    <div className="games__games">
      {games?.map((el, indx) => (
        <GameItem currentGame={el} key={el.game_id || indx} name={el.name} image={el.image} />
      ))}
    </div>
  );

  return (
    <div className="games__providers">
      {providers.map((provider) => {
        const providerName = provider.name;
        const games = providersGames.filter(
          (el) => el.provider == providerName || el.providerName == providerName
        );

        return (
          <div className="game__providers__item" key={provider.name}>
            <h2 style={headerStyle}>{provider.name}</h2>
            <GamesItems games={games} />
          </div>
        );
      })}
    </div>
  );
}
