import { useEffect, useMemo } from "react";
import { useAppSelector } from "../../store/index.ts";
import { useDispatch } from "react-redux";
import { setPageWitchMenuHeight } from "../../store/siteSlice";
import { useLocation } from "react-router-dom";
import { useDebounceCallback } from "usehooks-ts";

export default function useResizeListener() {
  const dispatch = useDispatch();
  const pageContent = useAppSelector((state) => state.site.pageWitchMenuContent);
  const location = useLocation();
  const pathname = useMemo(() => location.pathname, [location]);
  const setPageHeightDebounce = useDebounceCallback(
    (val) => dispatch(setPageWitchMenuHeight(val)),
    300
  );

  useEffect(() => {
    dispatch(setPageWitchMenuHeight(window.innerHeight));
  }, [pathname]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const clientHeight = entry.target.clientHeight;

        // dispatch(setPageWitchMenuHeight(window.innerHeight));

        setTimeout(() => {
          setPageHeightDebounce(clientHeight);
        }, 100);
      });
    });

    if (pageContent) {
      resizeObserver.observe(pageContent);
    }

    return () => {
      resizeObserver.disconnect();
      dispatch(setPageWitchMenuHeight(window.innerHeight));
    };
  }, [pageContent]);
}
