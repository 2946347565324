import React from "react";
import { useAppSelector } from "../../../../store/index.ts";
import ShowContainer from "../../../mui/ShowContainer.jsx";
import BannerItem from "../../../landing/banners/banner_item/BannerItem.jsx";
import "./dynamic-banners.scss";
import CustomCarousel from "../../../mui/custom-carousel/CustomCarousel.jsx";
import useBanners from "../../../../hooks/other/useBanners";

export default function DynamicBanners() {
  const banners = useBanners();
  const bannerPosition = useAppSelector((state) => state.site.bannerPosition);

  return (
    <ShowContainer condition={banners.length}>
      <section className="dynamic-banners">
        {banners.length > 1 ? (
          <CustomCarousel
            autoplay={true}
            autoplaySpeed={500}
            loop
            margin={8}
            className="dynamic-banners__carousel"
            dots={true}
            nav={false}
            draggable={true}
            startPosition={bannerPosition}
            items={1}>
            {banners.map((el, indx) => (
              <BannerItem key={el.id} {...el} position={indx} />
            ))}
          </CustomCarousel>
        ) : (
          <div className="dynamic-banners__carousel ">
            <BannerItem {...banners[0]} />
          </div>
        )}
      </section>
    </ShowContainer>
  );
}
