import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useEventListener } from "usehooks-ts";
import IconEye from "../JSXIcons/IconEye";
import IconEyeHide from "../JSXIcons/IconEyeHide";
import IconCopy from "../JSXIcons/IconCopy";
import { successToast } from "../Toaster";
import "./custom-input.scss";
import ShowContainer from "../ShowContainer";

/**
 * @typedef {Object} CusInputProps
 * @property {string} [label] - Заголовок для input.
 * @property {string | React.ReactNode} [placeholder] - Плейсхолдер, может быть строкой или JSX.
 * @property {'text' | 'password' | 'email' | 'number' | 'tel' | 'url'} [type] - Тип input.
 * @property {string} [icon] - Иконка (путь к изображению).
 * @property {string} [grayWarning] - Дополнительное предупреждение.
 * @property {number} [minLength] - Минимальная длина.
 * @property {number} [maxLength] - Максимальная длина.
 * @property {string} [dataType] - Тип данных (например, email, password).
 * @property {boolean} [required] - Поле обязательное.
 * @property {string} [name] - Имя поля.
 * @property {string} [defaultValue] - Значение по умолчанию.
 * @property {React.ReactNode} [iconJSX] - JSX-элемент для иконки.
 * @property {React.ReactNode} [iconJSXstart] - JSX-элемент для иконки в начале.
 * @property {function} [onChange] - Обработчик изменения значения.
 * @property {string} [errorWarning] - Ошибка, которую нужно отобразить.
 * @property {boolean} [readOnly] - Если true, поле только для чтения.
 * @property {boolean} [isCopy] - Если true, показывать кнопку копирования.
 * @property {string} [plugText] - Текст для отображения в поле.
 * @property {function} [onKeyDown] - Обработчик для onKeyDown.
 * @property {function} [onKeyUp] - Обработчик для onKeyUp.
 * @property {number} [min] - Минимальное значение (для числовых полей).
 * @property {number} [max] - Максимальное значение (для числовых полей).
 * @property {import("react").HTMLInputAutoCompleteAttribute} [autocomplete] - Значение для автозаполнения
 * @property { "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined } [inputMode] - Значение для автозаполнения
 */

/**
 * Компонент для ввода с кастомизацией.
 * @param {CusInputProps} props
 * @returns {JSX.Element}
 */
const CusInput = ({
  label,
  placeholder,
  type,
  icon,
  grayWarning,
  minLength,
  maxLength,
  dataType,
  required,
  name,
  defaultValue,
  iconJSX,
  iconJSXstart,
  onChange,
  errorWarning,
  readOnly,
  isCopy,
  plugText,
  onKeyDown,
  onKeyUp,
  min,
  max,
  autocomplete = "off",
  inputMode = undefined
}) => {
  const { t } = useTranslation();
  const [showPas, setShowPas] = useState(false);
  const [error, setError] = useState("");
  const typeInput = type === "password" && showPas ? "text" : type;
  const inputRef = useRef();

  useEventListener("inputError", (e) => {
    const detail = e.detail;
    if (detail.target === inputRef.current) setError(detail.message);
  });

  useEffect(() => {
    setError(errorWarning);
  }, [errorWarning]);

  const isCopyClass = isCopy ? "is-copy" : "";

  return (
    <div
      className={`custom-input  ${error ? "error-container" : ""} ${iconJSXstart ? "icon-JSX-start" : ""}`}>
      <ShowContainer condition={label}>
        <span className="custom-input__title">
          {t(label)}
          {required && <span className="required"> *</span>}
        </span>
      </ShowContainer>
      <div
        className={`custom-input__container ${error ? "error" : ""} ${plugText ? "plug-text-" + plugText.length : ""} `}>
        {!!icon && <img src={icon} alt="" />}
        <div className="icon-JSX-start__div">{!!iconJSXstart && iconJSXstart}</div>
        <span className="plug-text">{plugText}</span>
        <input
          defaultValue={defaultValue}
          ref={inputRef}
          readOnly={readOnly}
          onChange={(e) => {
            setError("");
            onChange?.(e);
          }}
          data-required={required}
          data-minlength={minLength}
          data-maxlength={maxLength}
          data-min={min}
          data-max={max}
          data-type={dataType}
          inputMode={inputMode}
          type={typeInput}
          name={name}
          autoComplete={autocomplete}
          placeholder={
            typeof placeholder === "string" ? t(placeholder || label) : placeholder || label
          }
          className={`custom-input__input ${isCopyClass} ${type || ""} ${iconJSX ? "icon" : ""}`}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
        <ShowContainer condition={iconJSX}>
          <button type="button" className="password__hide__btn">
            {iconJSX}
          </button>
        </ShowContainer>

        <ShowContainer condition={type === "password"}>
          <button
            type="button"
            className="password__hide__btn"
            onClick={(e) => {
              e.preventDefault();
              setShowPas((prev) => !prev);
            }}>
            {showPas ? <IconEye /> : <IconEyeHide />}
          </button>
        </ShowContainer>

        <ShowContainer condition={isCopy}>
          <button
            type="button"
            className="password__hide__btn"
            onClick={() => {
              navigator.clipboard.writeText(defaultValue);
              successToast(t("successfully copied to clipboard"));
            }}>
            <IconCopy />
          </button>
        </ShowContainer>
      </div>
      {!!error && <span className="text-error">{t(error)}</span>}
      {grayWarning && <span className="custom-input__gray-warning">{t(grayWarning)}</span>}
    </div>
  );
};

export default CusInput;
